@import '~theme/viewport.module.css';

.SearchHero-content {
  text-align: center;

  @media (--viewport-md) {
    text-align: left;
  }
}

.SearchHero-imageContainer {
  height: 100%;
  margin: 0;
  position: relative;
  width: 100%;
}

.SearchHero-image {
  border-radius: var(--space-md);
  height: auto;
  object-fit: contain;
  width: 100%;
}

.SearchHero-imageCaption {
  color: var(--color-white);
  font: var(--font-style-content-3);
  left: 18px;
  position: absolute;
  top: 6px;
  z-index: var(--z-index-raised);
}

.SearchHero-advisorBanner {
  position: relative;
  top: -130px;
  width: 290px;
  z-index: var(--z-index-raised);

  @media (--viewport-lg) {
    position: static;
    width: 380px;
  }
}
